.hero-container {
  background-color: #3D3737;
  background-image: url("../../assets/overlay.png");
  background-size: cover;
  padding: 6rem 1rem 8rem;
  color: #fff;
  text-align: center;

  .hero-text {
    margin-bottom: 2rem;
    color: #efe5cb;
    font-size: 1.2rem;
    line-height: 2rem;
  }

  .hero-title {
    line-height: 3rem;
    font-weight: 500;
  }

  .button-container {
    margin-top: 7rem !important;
    .button {
      width: 70%;
      background: transparent;
      padding: 1rem 0;
      border: 2px solid #bf9e55;
      border-radius: 25px 0 25px 0;
      margin: 0 auto;
      margin-bottom: 1rem;

      &.primary {
        background-color: #bf9e55;
        color: #16171d;
      }
    }
  }

  @media screen and (min-width: 750px) {
    .hero-title {
      font-size: 4rem;
      line-height: 5rem;
      width: 90%;
      margin: 0 auto;
    }
    .hero-text {
      font-size: 1.6rem;
    }
    .button-container {
      width: 60%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      .button {
        width: 200px;
        font-size: 1.4rem;
      }
    }
  }

  @media screen and (min-width: 1024px) {
    padding-top: 15rem;

    .hero-title {
      font-size: 4rem;
      line-height: 5rem;
      width: 70%;
      margin: 0 auto;
    }
  }

  @media screen and (min-width: 1420px) {
    .hero-title {
      font-size: 6rem;
      line-height: 6rem;
    }

    .hero-text {
      font-size: 2rem;
    }

    .button-container {
      width: 50%;
      justify-content: space-between;
      .button {
        width: 30%;
      }
    }
  }

  @media screen and (min-width: 2200px) {
    .hero-title {
      font-size: 8rem;
      line-height: 10rem;
      width: 70%;
    }

    .hero-text {
      font-size: 2.6rem;
    }
    .button-container {
      width: 40%;
      .button {
        padding: 1.5rem;
        width: 40%;
        font-size: 2rem;
      }
    }
  }
}
