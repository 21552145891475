.howItWorks {
  color: #fff;
  padding: 0 0 7rem;
  margin-top: 3rem;
  background: #3D3737;

  main {
    padding: 1rem 1rem;
    .image {
      width: fit-content;
      img {
        width: 70%;
        display: block;
        margin: 0 auto;
      }
    }
    .text {
      margin-top: 2rem;
      line-height: 2rem;
      color: #9ba5b2;
    }
  }

  @media screen and (min-width: 400px) {
    main {
      padding: 1rem 2rem;
    }
  }

  @media screen and (min-width: 768px) {
    main {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-around;
      align-items: center;
      .image {
        width: 50% !important;
      }
      .text {
        width: 50%;
        margin-top: 0;
      }
    }
  }
  @media screen and (min-width: 900px) {
    padding-inline: 5rem;
  }
  @media screen and (min-width: 1024px) {
    main {
      .image {
        width: 500px;
      }
      .text {
        width: 50%;
        font-size: 1.2rem;
        line-height: 2.5rem;
      }
    }
  }
  @media screen and (min-width: 1600px) {
    main {
      margin: 0 auto;
      width: 70%;
    }
    .text {
      font-size: 1.4rem;
      line-height: 3rem !important;
    }
  }
}
