.features {
  color: #fff;
  margin-top: 3rem;
  padding-bottom: 4rem;
  background: #3d3737;

  .main {
    background-color: #16171d;
    margin-top: 3rem;
    padding: 4rem 1rem;

    .feature {
      text-align: center;
      padding: 4rem 1.4rem;
      border: 1px solid #787a8d;
      border-radius: 10px;
      margin-bottom: 2rem;

      .icon {
        display: inline-block;
        margin-bottom: 2rem;
      }

      .title {
        margin-bottom: 1rem;
      }

      .text {
        color: #787a8d;
      }
    }
  }

  @media screen and (min-width: 400px) {
    .feature-list {
      display: flex;
      flex-wrap: wrap;
      gap: 2rem;
    }
    .feature {
      width: 200px;
      flex-grow: 1;
    }
  }

  @media screen and (min-width: 768px) {
    .main {
      padding: 6rem 3rem;
    }
  }

  @media screen and (min-width: 1600px) {
    .feature-list {
      margin: 0 auto;
      width: 70%;
    }
  }
}
