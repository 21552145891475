.footer-container {
  background-image: url("../../assets/Footer/background.png");
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  margin: 5rem auto 0;
  padding: 3rem 4rem;
  text-align: center;
  .logo {
    width: 150px;
    margin-bottom: 4rem;
  }

  .subscribe {
    color: #fff;
    font-size: 1.8rem;
    display: inline-block;
    margin: 1.5rem 0;
  }
  P {
    color: #9ba5b2;
    line-height: 2;
    width: 500px;
    margin: 0 auto;
  }

  ul {
    margin: 0 auto;
    margin-top: 5rem;
    display: flex;
    gap: 1rem 3rem;
    flex-wrap: wrap;
    width: fit-content;
    padding-left: 1rem;

    li > a {
      color: #fff;
      font-size: 1.2rem;
    }
  }

  &__socials {
    width: fit-content;
    margin: 5rem auto 3rem;

    &__set {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 0.5rem;
      width: 450px;
      &__icon {
        width: 40px;
      }
    }
  }

  .bottom-links {
    display: flex;
    width: fit-content;
    margin: 3rem auto;
    gap: 1rem;
    a {
      color: #9ba5b2;
    }
  }
  .copyright{
   color: #fff;
  }

  @media screen and (max-width: 535px) {
    padding: 3rem 2rem 5rem !important;
    p {
      width: 100%;
    }
    ul {
      display: block;
      li {
        margin-bottom: 1rem;
      }
    }

    &__socials {
      &__set {
        width: 100% !important;
      }
    }
  }
  @media screen and (max-width: 768px) {
    padding: 3rem 1rem 1rem;

    &__socials {
      &__set {
        width: 500px;

        &__icon {
          width: 40px;
        }
      }
    }
  }

  @media screen and (min-width: 1024px) {
    &__socials {
      &__set {
        margin: 0 auto;
      }
    }
  }
}
