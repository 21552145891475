.heading {
  color: #fff;
  text-align: center;
  margin-bottom: 3rem;
  padding-top: 4rem;
  .heading-text {
    margin-top: .5rem;
    font-size: 2rem;
    font-weight: normal;
  }
}
.bar {
  width: 140px;
  height: 4px;
  background-color: #bf9e55;
  margin: 0 auto;
}
