*,
*::before,
*::after {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}
body {
  height: 200vh;
  background: #312a2a;
  font-family: "Space Grotesk", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
