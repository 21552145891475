.overview {
  color: #fff;
  margin-top: 3rem;
  padding: 0 1rem 7rem;
  background: #3d3737;

  .overview-image {
    margin-bottom: 2rem;
    width: 80%;
  }
  .rows {
    .row1 {
      margin-bottom: 6rem;
    }
  }

  h2 {
    margin-bottom: 1rem;
  }
  .text-container {
    width: 100% !important;
  }
  .text {
    line-height: 1.8rem;
  }

  @media screen and (max-width: 500px) {
    .overview-image {
      display: block;
      width: 80% !important;
      margin: 0 auto;
    }
  }
  @media screen and (max-width: 900px) {
    padding: 0 2rem 7rem;
    .overview-image {
      display: block;
      margin: 0 auto;
    }
  }
  @media screen and (min-width: 900px) {
    padding: 0 5rem 7rem;

    .rows {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .row1,
    .row2 {
      display: flex;
      gap: 3rem;
    }
    .row2 {
      flex-direction: row-reverse;
    }
  }
  .overview-image {
    margin-bottom: 2rem;
    width: 300px;
  }

  .text-container {
    width: 50%;
  }

  @media screen and (min-width: 1300px) {
    h2 {
      font-size: 2rem;
    }
    .text {
      font-size: 1.2rem;
      line-height: 2.2rem;
    }
  }
  @media screen and (min-width: 1600px) {
    .inner {
      width: 70%;
      margin: 0 auto;
    }
  }
}
