.waitinglist-container {
  color: #fff;
  margin: 6rem 0;
  padding: 0 0.5rem;

  .arrow {
    display: none;
  }

  .body-texts {
    line-height: 2rem;
    text-align: center;
    margin-bottom: 3rem;
  }
  &__form {
    background: #202129;
    border: 1px solid #F1C767;
    border-radius: 20px;
    padding: 2rem 1rem 3rem;
    .form-heading {
      margin-bottom: 1.3rem;
      h1 {
        font-size: 1.4rem;
      }
      text-align: center;
    }
    input {
      display: block;
      margin: 1.3rem 0;
      padding: 1rem 1rem;
      width: calc(100% - 2.3rem);
      border-radius: 5px;
      outline: none;
      border: 2px solid #c6c6c61a;
      background-color: #331253;
      color: #fff;
      font-size: 1.1rem;

      &::placeholder {
        color: #8b83a5;
      }
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    .form-submit {
      width: 40%;
      background-color: #BF9E55;
      display: flex;
      justify-content: space-between;
      padding: 0.7rem 1rem;
      margin: 0 auto;
      margin-top: 2rem;
      border-radius: 30px 0 30px 0;
    }
  }

  .socials {
    display: flex;
    gap: 0.4rem;
    justify-content: center;
    margin: 2rem 0 2rem;
    &__icons {
      width: 30px;
    }
  }

  @media screen and (min-width: 375px) {
    padding: 1rem;
    &__form {
      .socials {
        &__icons {
          width: 40px;
        }
      }
    }
  }

  @media screen and (min-width: 550px) {
    &__form {
      padding: 2rem 2rem 3rem;

      width: 350px;
      margin: 0 auto;
    }
  }

  @media screen and (min-width: 768px) {
    padding: 5rem;

    display: flex;
    align-items: center;

    .arrow {
      display: block;
      width: 80%;
      margin: 0 auto;
    }
    .join {
      flex: 1;
    }

    .form-con {
      flex: 1;
    }

    .body-texts {
      text-align: start;
    }
  }

  @media screen and (min-width: 1600px) {
    width: 1400px;
    margin: 0 auto;
  }

  @media screen and (min-width: 1500px) {
    &__form {
      width: 500px !important;
    }
  }
}
