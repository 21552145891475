.navBar {
  padding: 1rem 1.5rem;
  background-color: #312a2a;
  .hamburger-container {
    z-index: 2;
    position: fixed;
    top: 1rem;
    right: 1rem;
    width: 60px;
    height: 50px;
    .hamburger {
      width: 35px;
      background: #fff;
      height: 2px;
      transform: translateY(2rem);
      right: 1rem;
      position: absolute;
      transition: all 300ms ease-in-out;
      &::before {
        content: "";
        position: absolute;
        transform: translateY(-0.5rem);
        width: 35px;
        background: #fff;
        height: 2px;
        transition: all 300ms ease-in-out;
      }
      &::after {
        content: "";
        position: absolute;
        transform: translateY(0.5rem);
        width: 35px;
        background: #fff;
        height: 2px;
        transition: all 300ms ease-in-out;
      }
    }

    &.active {
      .hamburger {
        width: 30px;

        background: transparent;
        &::before {
          transform: translate(0, 0rem) rotate(45deg);
          width: 30px;
        }
        &::after {
          transform: translate(0, 0rem) rotate(-45deg);
          width: 30px;
        }
      }
    }
  }

  .logo {
    width: 120px;
  }
  a {
    text-decoration: none;
    color: #fff;
  }
  .menuItems-mobile {
    padding: 2rem 1rem;
    background-color: #312a2a;
    position: fixed;
    top: 0;
    left: 100vw;
    height: 100vh;
    width: 100vw;
    transition: all 0.3s ease-in-out;

    ul {
      margin: 4rem 0 4rem 1rem;
      li {
        list-style: none;
        font-size: 2rem;
        margin-bottom: 0.8rem;
      }
    }
    .subscribe {
      background-color: #bf9e55;
      width: fit-content;
      margin: 0 auto;
      transform: translateX(-1rem);
      padding: 0.7rem 0;
      width: 240px;
      text-align: center;
      border-radius: 25px 0 25px 0;

      a {
        color: #fff;
        font-size: 1.6rem;
      }
    }
  }

  .menuItems-mobile.show {
    left: 0;
  }

  .menuItems {
    display: none;
  }
  @media screen and (min-width: 1024px) {
    padding: 1rem 2rem;
    position: fixed;
    width: calc(100vw - 4rem);
    display: flex;
    justify-content: space-between;
    .menuItems-mobile {
      display: none;
    }
    .hamburger-container {
      display: none;
    }

    .menuItems {
      display: flex;
      justify-content: space-between;
      width: fit-content;
      align-items: center;
      right: 1rem;
      ul {
        display: flex;
        list-style: none;
        margin-right: 3rem;

        li {
          margin-right: 1rem;
          padding: 1rem 1rem;
          transition: all 0.2s ease-in-out;

          &:hover {
            background-color: rgba(255, 255, 255, 0.35);
            border-radius: 5px;
          }
        }
      }
      .subscribe {
        padding: 1rem 0;
        width: 200px;
        background-color: #bf9e55;
        text-align: center;
        border-radius: 35px 0 35px 0;
      }
    }
  }

  @media screen and (min-width: 1120px) {
    .logo {
      width: 140px;
    }
    li {
      font-size: 1.3rem;
    }
  }
}
