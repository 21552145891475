.mobile-app {
  color: #fff;
  padding: 0.5rem;
  margin: 8rem 0;
  main {
    background-image: url("../../assets/MobileApp/background-image.png");
    background-size: cover;
    text-align: center;
    padding: 6rem 1rem;

    h1 {
      margin-bottom: 1rem;
    }
    .text {
      line-height: 2rem;
    }

    .buttons {
      margin-top: 2rem;
      .button {
        img {
          width: 200px;
          display: block;
          margin: 0 auto;
          margin-bottom: 1rem;
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    padding-inline: 2rem;
    main {
      padding: 3rem 5rem 6rem;
      border-radius: 30px;
    }
    .buttons {
      display: flex;
      width: fit-content;
      gap: 2rem;
      margin: 0 auto;
    }
  }

  @media screen and (min-width: 1024px) {
    .text {
      width: 60%;
      margin: 0 auto;
    }
  }
}
