.faq {
  background: #3d3737;
  padding: 0.5rem 0;
  color: #fff;
  .section {
    background-color: #202020;
    padding: 1rem;
    margin-bottom: 1rem;
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 1rem 0;
    .title-text {
      display: flex;
      align-items: center;
      gap: 1rem;
      line-height: 2;
    }
  }

  .drop {
    transition: all 300ms ease-in-out;
  }
  p {
    color: #9ba5b2;
    line-height: 2;
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: all 400ms ease-in-out;
  }

  .question.active {
    .drop {
      transform: rotate(90deg);
    }
    p {
      opacity: 1;
      max-height: 300px;
      height: 100%;
      padding-bottom: 1rem;
    }
  }
  @media screen and (min-width: 375px) {
    padding: 1rem 1rem;
  }
  @media screen and (min-width: 425px) {
    padding: 1rem 2rem;
  }
  @media screen and (min-width: 768px) {
    .sections {
      display: flex;
      gap: 2rem;
    }
  }
  @media screen and (min-width: 1024px) {
    padding: 1rem 5rem;
  }
  @media screen and (min-width: 1400px) {
    width: 70%;
    margin: 0 auto;
  }
}
