.about {
  background: #3D3737;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 3rem;
  padding: 0 1rem 7rem;
  text-align: center;
  color: #fff;

  

  .about-text {
    font-size: 1.1rem;
    line-height: 2rem;

    &.follow {
      margin-top: 2rem;
    }
  }

  @media screen and (min-width: 425px) {
    padding-inline: 2rem;
  }

  @media screen and (min-width: 900px) {
    .about-text {
      width: 90%;
      margin: 0 auto;
    }
  }
  @media screen and (min-width: 1200px) {
    .about-text {
      font-size: 1.2rem;
      line-height: 2.4rem;
    }
  }
  @media screen and (min-width: 1500px) {
    .about-text {
      font-size: 1.8rem;
      line-height: 3rem;
      width: 75%;
      margin: 0 auto;
    }
  }
}
